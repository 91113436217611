import * as Sentry from '@sentry/react';
import { isFacebookBrowser, isInstagramBrowser, os } from 'common/BrowserUtilities';
import { ClientEvents } from 'common/events/ClientEvents';
import React, { useCallback, useMemo } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useLoginOrSignUpWithOAuth } from 'src/auth/oAuth/useLoginOrSignUpWithOAuth';
import { CurrentUserFieldsFragment, OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { Google } from 'src/shared/design-system/Icon/Google';
import { LoginButtonV2 } from 'src/shared/design-system/Login/LoginButtonV2';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useTracker } from 'src/shared/hooks/useTracker';
import { getFullRelativeUrl, getParam } from 'src/shared/utils/RouteUtilities';

// Pull autologin defs out if it become useful outside of Google.
enum Autologin {
  Google = 'google',
}
const AUTOLOGIN_KEY = 'autologin';

interface IGoogleLoginV2Props {
  onSuccess?: (isExistingUser: boolean, currentUser: CurrentUserFieldsFragment) => void;
  onError: (error: Error) => void;
  onAttempt?: () => void;
  onMissingFields: (type: OAuthLoginType, token: string, missingFieldsResult: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
  renderButton?: ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => JSX.Element;
}

/**
 * Check for if we know Google SSO does not support the browser.
 * See: https://developers.googleblog.com/2020/08/guidance-for-our-effort-to-block-less-secure-browser-and-apps.html
 */
const isUnsupportedBrowser = (): boolean =>
  isFacebookBrowser(navigator.userAgent) || isInstagramBrowser(navigator.userAgent);

const isOfflineResponse = (
  response: GoogleLoginResponse | GoogleLoginResponseOffline
): response is GoogleLoginResponseOffline =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  typeof (response as GoogleLoginResponseOffline).code === 'string';
const removeProtocol = (url: string): string => url.replace(/^(https?:|)\/\//, '');

export const GoogleLoginV2: React.FC<IGoogleLoginV2Props> = (props) =>
  isUnsupportedBrowser() ? <GoogleExternalBrowserButton {...props} /> : <GoogleLoginWithClientId {...props} />;

const GoogleExternalBrowserButton: React.FC<IGoogleLoginV2Props> = ({ renderButton, onError, onAttempt }) => {
  const location = useLocation();
  const tracker = useTracker();

  Sentry.captureMessage(`Location object in Meta browser: ${JSON.stringify(location)}`, {
    level: 'info',
  });

  const externalLink = useMemo(() => {
    // Add query parameter to autobegin login process.
    const redirectUrl = new URL(getFullRelativeUrl(location), process.env.REACT_APP_FRONT_END_URL);
    redirectUrl.searchParams.set(AUTOLOGIN_KEY, Autologin.Google);
    const link = redirectUrl.toString();

    // We need different url schemes/links based on os.
    const osType = os(navigator.userAgent);

    // Protocol in iOS is determined by scheme: https://developer.chrome.com/docs/multidevice/ios/links/
    if (osType === 'iOS') {
      return `googlechromes://${removeProtocol(link)}`;
    }

    if (osType === 'Android') {
      return `googlechrome://navigate?url=${link}`;
    }

    return null;
  }, [location]);

  const onClick = useCallback(() => {
    tracker.track(ClientEvents.SSO_SIGNUP_CLICKED, { sso_name: 'google_v2' });
    if (onAttempt) {
      onAttempt();
    }

    if (externalLink) {
      window.location.href = externalLink;
    }

    // Fallback in case we can't open external link.
    setTimeout(() => {
      onError(
        new Error('Google login does not support this browser. Please try opening this page in a supported browser.')
      );
    });
  }, [onAttempt, onError, externalLink]);

  if (renderButton) {
    return renderButton({ onClick });
  }
  return (
    <LoginButtonV2 icon={<Google />} onClick={onClick}>
      Continue with Google
    </LoginButtonV2>
  );
};

const GoogleLoginWithClientId: React.FC<IGoogleLoginV2Props> = ({
  onSuccess,
  onError,
  onAttempt,
  renderButton,
  onMissingFields,
  requireZipCode,
  signUpEntryPoint,
}) => {
  if (typeof process.env.REACT_APP_GOOGLE_CLIENT_ID === 'undefined') {
    throw new Error('Missing env variable: REACT_APP_GOOGLE_CLIENT_ID');
  }

  const { login, handleError } = useLoginOrSignUpWithOAuth({
    type: OAuthLoginType.GoogleWeb,
    onError,
    onSuccess,
    onMissingFields,
    requireZipCode,
    signUpEntryPoint,
  });

  const handleLoginAttempt = () => {
    if (onAttempt) {
      onAttempt();
    }
  };

  const handleLoginSuccess = (success) => {
    if (isOfflineResponse(success)) {
      handleError(new Error('Failed to log in with Google. Please try again.'));
    } else {
      login(success.getAuthResponse().id_token);
    }
  };

  const handleLoginFailure = (error: any) => {
    // Don't show an error if it's an initialization failure. This happens in non-cookie environments, and is a known issue.
    // https://app.asana.com/0/1203597893377382/1203792899032499/f
    if (error.error !== 'idpiframe_initialization_failed') {
      // This message is not shown to users - a generic one is shown instead
      handleError(new Error(`Failed to log in with Google: ${error}`));
    }
  };

  const renderGoogleButton =
    renderButton ??
    (({ onClick, disabled }) =>
      disabled ? (
        <></>
      ) : (
        <LoginButtonV2 icon={<Google />} onClick={onClick}>
          Continue with Google
        </LoginButtonV2>
      ));

  // Given the query parameter, autoload login process.
  const autoLoad = useMemo(() => {
    const autologin = getParam(window.location, AUTOLOGIN_KEY);
    return Boolean(autologin && autologin === Autologin.Google);
  }, []);

  return (
    <GoogleLogin
      autoLoad={autoLoad}
      render={renderGoogleButton}
      onRequest={handleLoginAttempt}
      onSuccess={handleLoginSuccess}
      onFailure={handleLoginFailure}
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      cookiePolicy={'single_host_origin'}
    />
  );
};
