import { ClientEvents } from 'common/events/ClientEvents';
import { AuthMethod } from 'common/events/ClientTypes';
import { GraphQLFormattedError } from 'graphql';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import {
  AuthResult,
  AuthResultFieldsFragment,
  CurrentUserFieldsFragment,
  OAuthLoginType,
  OAuthMissingFieldsResult,
  useLoginWithOAuthMutation,
} from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useTracker } from 'src/shared/hooks/useTracker';
import { useUserPreferences } from 'src/user-preferences/useUserPreferences';
import { usePromotionalsHook } from '../../promotionals/usePromotionalsHook';
import { IAuthResponse } from '../IAuthResponse';
import { useShowLoginOrSignUpModal } from '../modal/useShowLoginOrSignUpModal';
import { useHandleLogin } from '../useHandleLogin';
import { getLoginAndSignUpMethodFromOAuthLoginType } from './getLoginAndSignUpMethodFromOAuthLoginType';

const OAUTH_LOGIN_TYPE_TO_AUTH_METHOD_RECORD: Record<OAuthLoginType, AuthMethod> = {
  [OAuthLoginType.Apple]: 'apple',
  [OAuthLoginType.Facebook]: 'facebook',
  [OAuthLoginType.GoogleAndroid]: 'google',
  [OAuthLoginType.GoogleIos]: 'google',
  [OAuthLoginType.GoogleWeb]: 'google',
};

interface ILoginOptions {
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  hasAcceptedTermsOfService?: boolean;
  signUpEntryPoint?: string | null;
}

const isOAuthMissingFieldsResult = (
  mutationData: OAuthMissingFieldsResult | AuthResultFieldsFragment
): mutationData is OAuthMissingFieldsResult => mutationData.__typename === 'OAuthMissingFieldsResult';

const isSuccessfulOAuthResult = (
  mutationData: OAuthMissingFieldsResult | AuthResultFieldsFragment
): mutationData is AuthResult => mutationData.__typename === 'AuthResult';

interface IUseLoginOrSignUpWithOAuthParams {
  type: OAuthLoginType;
  onMissingFields?: (type: OAuthLoginType, token: string, missingFields: OAuthMissingFieldsResult) => void;
  onSuccess?: (isExistingUser: boolean, currentUser: CurrentUserFieldsFragment) => void;
  onError?: (error: Error) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
}

export const useLoginOrSignUpWithOAuth = ({
  type,
  onMissingFields,
  onSuccess,
  onError,
  requireZipCode,
  signUpEntryPoint,
}: IUseLoginOrSignUpWithOAuthParams) => {
  const tracker = useTracker();
  const [loginWithOAuth] = useLoginWithOAuthMutation();
  const handleLogin = useHandleLogin();
  const [{ utm, zipcode: cachedZipCode }] = useUserPreferences();
  const { code } = usePromotionalsHook();
  const { signUpPromoCode } = useShowLoginOrSignUpModal();
  const promoCode = code?.code;

  // Ideally we check for currentUserIsLoading and queue the login, but this gets us 99% of the way there
  const [currentUser] = useCurrentUser();

  const onOAuthSuccess = useCallback(
    (type: OAuthLoginType, { user, token, isExistingUser }: IAuthResponse) => {
      const { signUpMethod } = getLoginAndSignUpMethodFromOAuthLoginType(type);
      handleLogin(signUpMethod, { user, token, isExistingUser });

      tracker.track(ClientEvents.SSO_AUTH_SUCCESS, {
        method: OAUTH_LOGIN_TYPE_TO_AUTH_METHOD_RECORD[type],
      });

      onSuccess?.(isExistingUser, user);
    },
    [tracker, handleLogin, onSuccess]
  );

  const handleError = useCallback(
    (error: Error | GraphQLFormattedError) => {
      console.error(error);
      const errorProperties = {
        method: OAUTH_LOGIN_TYPE_TO_AUTH_METHOD_RECORD[type],
        message: error.message,
        stack: error instanceof Error ? error.stack : undefined,
      };
      tracker.track(ClientEvents.SSO_AUTH_FAILED, errorProperties);

      onError?.(error instanceof Error ? error : new Error(error.message));
    },
    [onError, tracker, type]
  );

  const login = useCallback(
    async (token: string, options?: ILoginOptions) => {
      try {
        if (!isNil(currentUser)) {
          return;
        }
        const { firstName, lastName, zipCode, hasAcceptedTermsOfService, signUpEntryPoint } = options ?? {};
        const { data, errors } = await loginWithOAuth({
          variables: {
            oAuthLoginType: type,
            token,
            zipCode: zipCode ?? cachedZipCode,
            requireZipCode,
            signUpEntryPoint,
            promoCode: signUpPromoCode?.code ?? promoCode,
            utm,
            firstName,
            lastName,
            hasAcceptedTermsOfService,
          },
        });

        if (errors?.length) {
          handleError(errors[0]);
          return;
        }

        if (!data) {
          return;
        }

        if (isOAuthMissingFieldsResult(data.login)) {
          if (!isNil(onMissingFields)) {
            onMissingFields(type, token, data.login);
            return;
          }
          handleError(new Error('Missing fields, but no handler.'));
        }

        if (isSuccessfulOAuthResult(data.login)) {
          onOAuthSuccess(type, data.login);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [
      loginWithOAuth,
      type,
      cachedZipCode,
      requireZipCode,
      promoCode,
      utm,
      signUpEntryPoint,
      handleError,
      onMissingFields,
      onOAuthSuccess,
    ]
  );

  return { login, handleError };
};
